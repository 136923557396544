<template>
  <nav>
    <router-link v-if="username !== 'CLAIMS'" :to="{ name: 'collectables'}" v-slot="{isActive}" class="routing-link mr-12">
      <span class="opacity-50 font-bold py-0.5 hover:opacity-100" :class="{'active': isActive}">Collectables</span>
    </router-link>
    <router-link v-if="username !== 'CLAIMS'" :to="{ name: 'hero'}" v-slot="{isActive}" class="routing-link mr-12">
      <span class="opacity-50 font-bold py-0.5 hover:opacity-100" :class="{'active': isActive}">Hero</span>
    </router-link>
    <router-link v-if="username !== 'CLAIMS'" :to="{ name: 'artists'}" v-slot="{isActive}" class="routing-link mr-12">
      <span class="opacity-50 font-bold py-0.5 hover:opacity-100" :class="{'active': isActive}">Artists</span>
    </router-link>
    <router-link v-if="username !== 'CLAIMS'" :to="{ name: 'spotlight'}" v-slot="{isActive}" class="routing-link">
      <span class="opacity-50 font-bold py-0.5 hover:opacity-100" :class="{'active': isActive}">Spotlight</span>
    </router-link>
    <router-link v-if="username === 'CLAIMS'" :to="{ name: 'claims'}" v-slot="{isActive}" class="routing-link">
      <span class="opacity-50 font-bold py-0.5 hover:opacity-100" :class="{'active': isActive}">Claims</span>
    </router-link>
  </nav>
</template>

<script>
import {getUsername} from '@/services/api/jwtService';
export default {
  name: "DesktopMenu",
  setup() {
    let username = getUsername()
    return {
      username
    }
  }
}
</script>

<style scoped>

</style>
