<template>
  <web3-provider>
    <!--  Modals -->
    <wallet-modal/>
    <!--  Modals END -->
    <mobile-menu/>
    <main id="main" class="flex flex-col justify-content-between min-h-screen z-10 bg-white text-black font-body">
      <Toast />
     <app-header/>
      <div class="router-wrap flex flex-grow">
        <Suspense>
          <template #default>
            <router-view/>
          </template>
           <template #fallback>
            <div>TROUBLE</div>
          </template>
        </Suspense>
      </div>
      <app-footer/>
    </main>
  </web3-provider>
</template>

<script>
import Web3Provider from "@/connector/Web3Provider"
import WalletModal from "@/components/WalletModal/WalletModal"
import MobileMenu from "@/components/Menu/MobileMenu";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import {useStore} from "vuex"

export default {
  name: 'App',
  components: {AppFooter, AppHeader, MobileMenu, WalletModal, Web3Provider},
  setup() {
    const store = useStore();
    const getExchangeRates = () => {
      store.dispatch('application/getExchangeRates')
    };
    const checkForLogin = () => {
      return store.dispatch('user/checkForLogin');
    }
    const setConstants = () => {
      store.dispatch('collectable/setConstants');
    }
    checkForLogin().then(isLoggedIn => {
      if (isLoggedIn) {
        setConstants();
      }
    });
    
    getExchangeRates();
  }

}
</script>
<style lang="scss" scoped>
.router-wrap {
  min-height: calc(100vh - 298px);
}
</style>
