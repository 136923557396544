<template>
  <div class="py-2.5 bg-black text-white">
    <container>
      <div class="flex justify-between flex-wrap">
         <div class="flex align-center py-1">
          <a href="https://app.sushi.com/pair/0xc5fa164247d2f8d68804139457146efbde8370f6"
            target="_blank"
            class="btn-exchange">
            <img src="@/assets/icons/icon--seen-green.svg" class="cursor-pointer mr-2" alt="SEEN">
            <span class="hidden md:block mr-1">Seen: </span>
            <p v-if="!seen"><i class="fas fa-spinner fa-spin"></i></p>
            <p v-else>{{formatCurrency(seen)}}</p>
          </a>
          <a href="https://app.sushi.com/pair/0xc5fa164247d2f8d68804139457146efbde8370f6"
            target="_blank"
            class="btn-exchange">
            <img src="@/assets/icons/icon--ethereum.svg" class="cursor-pointer mr-2" alt="SEEN">
            <span class="hidden md:block mr-1">Ethereum: </span>
            <p v-if="!ethereum"><i class="fas fa-spinner fa-spin"></i></p>
            <p v-else>{{formatCurrency(ethereum)}}</p>
          </a>
          <a href="https://app.sushi.com/pair/0xc5fa164247d2f8d68804139457146efbde8370f6"
            target="_blank"
            class="btn-exchange">
            <img src="@/assets/icons/icon--bitcoin.svg" class="cursor-pointer mr-2" alt="SEEN">
            <span class="hidden md:block mr-1">Bitcoin: </span>
            <p v-if="!bitcoin"><i class="fas fa-spinner fa-spin"></i></p>
            <p v-else>{{formatCurrency(bitcoin)}}</p>
          </a>
        </div>

        <div class="flex align-center py-1">
          <a class="btn-exchange cursor-pointer" @click="logout">
            <i class="fas fa-sign-out mr-2 text-lg"></i>
            Logout
          </a>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "@/components/Container";
import useExchangeRate from '@/hooks/useExchangeRate.js'
import {useStore} from "vuex"
import { useRouter } from "vue-router";

export default {
  name: 'TopHeaderBar',
  components: {Container},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { formatCurrency, seen, ethereum, bitcoin } = useExchangeRate();
    const logout = () => {
      store.dispatch('user/logout');
      router.push('login');
    }
    return { formatCurrency, seen, ethereum, bitcoin, logout };
  }
}
</script>

<style scoped lang="scss">
  .btn-exchange {
    @apply mr-8 text-sm inline-flex items-center;
    &:last-child {
      @apply mr-0;
    }
  }
</style>
