import {$axios} from './api/axios';
import store from '@/store'

$axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/`;
const getHeaders = (url) => {
    return !url.includes('admin') ? {} : {headers: {'Authorization': 'Bearer ' + store.getters['user/authToken']}}
}

export const ApiService = {
    init() {
        $axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/`;
        return $axios;
    },

    query(resource, params) {
        return $axios.get(resource, {params, ...getHeaders(resource)}).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    get(resource, slug = "") {
        return $axios.get(`${resource}/${slug}`, getHeaders(resource)).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    post(resource, params, config = null) {
        return $axios.post(`${resource}`, params, {...config, ...getHeaders(resource)});
    },

    update(resource, slug, params) {
        return $axios.put(`${resource}/${slug}`, params, getHeaders(resource));
    },

    put(resource, params) {
        return $axios.put(`${resource}`, params, getHeaders(resource));
    },

    delete(resource) {
        return $axios.delete(resource, getHeaders(resource)).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export const CollectablesService = {
    list(query = {perPage: 6, page: 1, includeIsHiddenFromDropList: true}) {
        // serializeđ
        return ApiService.query('collectables', query);
    },
    show(contractAddress) {
        return ApiService.get(`collectables/${contractAddress}`);
    },
    update(id, payload) {
        return ApiService.put(`admin/collectables/${id}`, payload);
    },
    create(payload) {
        return ApiService.post(`admin/collectables${payload.purchase_type === 1 ? '' : '/'}`, payload);
    },
    delete(id) {
        return ApiService.delete(`admin/collectables/${id}`);
    },
};

export const HeroService = {
    update(payload) {
        return ApiService.put(`admin/update-hero`, payload);
    }
}

export const ClaimsService = {
    list(query = {perPage: 5, page: 1}) {
        return ApiService.query('admin/claims', query);
    },
}

export const SpotlightService = {
    list() {
        return ApiService.get('admin/spotlight');
    },
};

export const UserService = {
    login(payload) {
        return ApiService.post('admin/login', payload);
    },
};

export const ConstantsService = {
    get() {
        return ApiService.get('admin/constants');
    },
};

export const MediaService = {
    preview(id) {
        return ApiService.put(`admin/media/${id}/preview`);
    },
    upload(payload) {
        return ApiService.post('admin/media', payload);
    },
    delete(id) {
        return ApiService.delete(`admin/media/${id}`);
    },
};

export const ArtistService = {
    getArtist(id) {
        return ApiService.get(`artists/${id}`);
    },
    list(query = {perPage: 6, page: 1, includeIsHiddenFromArtistList: true}) {
        // serializeđ
        return ApiService.query('artists', query);
    },
    update(id, payload) {
        return ApiService.put(`admin/artists/${id}`, payload);
    },
    create(payload) {
        return ApiService.post(`admin/artists`, payload);
    },
    delete(id) {
        return ApiService.delete(`admin/artists/${id}`);
    },
    search(query) {
        return ApiService.get(`artists`, `search?q=${query}`);
    }
};

export const ExchangeRateService = {
    get() {
        return $axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum%2Cbitcoin%2Cseen&vs_currencies=USD').catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};
