/* eslint-disable */
// import {AuthService} from '../api.service';
// import NProgress from 'nprogress';
// import store from '@/store';
import {$axios} from "./axios";
import { useRouter } from "vue-router";
import {ACCESS_TOKEN_ID, destroyToken, getToken, saveToken} from './jwtService';

var state = {
    pendingRequests: 0,
    originalRequest: {
        _retry: false
    }
};


const onSuccess = (response) => {

    state.pendingRequests--;
    if (state.pendingRequests === 0) {
        // NProgress.done();
    }

    if (response.data && response.data.accessToken) {
        saveToken(ACCESS_TOKEN_ID, response.data.accessToken);
    }

    // NProgress.done();
    return response.data;
};

const onError = (error) => {
    state.pendingRequests--;
    if (state.pendingRequests === 0) {
        // NProgress.done();
    }

    let errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : null;

    let errorCode = error.response && error.response.data && error.response.data.code
        ? error.response.data.code
        : null;

    if (!error.response) {
        return;
    }

    switch (error.response.status) {
        case 401:
        case 403:
            destroyToken(ACCESS_TOKEN_ID);
            if (!(useRouter().currentRoute.value.name === 'login')) useRouter().push('login')
            break;
        case 500:
            return Promise.reject(error.response);
            break;
    }
    // NProgress.done();
    return Promise.reject(error.response)
};

const beforeRequestSuccess = (config) => {
    state.pendingRequests++;
    const accesstoken = getToken(ACCESS_TOKEN_ID);
    if (accesstoken) {
        config.headers.Authorization = `Bearer ${accesstoken}`;
    }

    return config;
};

const beforeRequestError = (error) => {
    // NProgress.done();
    return Promise.reject(error);
};

export {onSuccess, onError, beforeRequestSuccess, beforeRequestError}
