import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/home/Home.vue'
import {ACCESS_TOKEN_ID, getToken} from '../services/api/jwtService';
import store from '@/store'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
    },
    {
        path: '/hero',
        name: 'hero',
        component: () => import(/* webpackChunkName: "hero" */ '../views/collectables/Hero.vue')
    },
    {
        path: '/collectables',
        name: 'collectables',
        component: () => import(/* webpackChunkName: "collectables" */ '../views/collectables/List.vue')
    },
     {
        path: '/collectables/new',
        name: 'collectable',
        component: () => import(/* webpackChunkName: "new-collectable" */ '../views/collectables/Detail.vue')
    },
    {
        path: '/collectables/:contractAddress',
        name: 'collectable',
        component: () => import(/* webpackChunkName: "collectable" */ '../views/collectables/Detail.vue')
    },
    {
        path: '/artists',
        name: 'artists',
        component: () => import(/* webpackChunkName: "artists" */ '../views/artists/List.vue')
    },
    {
        path: '/artists/new',
        name: 'artist',
        component: () => import(/* webpackChunkName: "new-artist" */ '../views/artists/Detail.vue')
    },
    {
        path: '/artists/:id',
        name: 'artist',
        component: () => import(/* webpackChunkName: "artist" */ '../views/artists/Detail.vue')
    },
    {
        path: '/spotlight',
        name: 'spotlight',
        component: () => import(/* webpackChunkName: "spotlight" */ '../views/spotlight/List.vue')
    },
    {
        path: '/claims',
        name: 'claims',
        component: () => import(/* webpackChunkName: "claims" */ '../views/claims/Claims.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !getToken(ACCESS_TOKEN_ID)) {
    store.dispatch('user/logout')
    next({ name: 'login' });
  } else {
    next();
  }
})

export default router
