<template>
  <container class="flex justify-center item-center h-full">
    <div class="w-96">
      <img src="https://miro.medium.com/max/960/0*Mif09Iu6iFryla-7.jpg" alt="meme">
    </div>
  </container>
</template>

<script>
import Container from "@/components/Container";

export default {
  name: "Home",
  components: {Container}
}
</script>

<style scoped>

</style>
