import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/index.scss'
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Toast from "primevue/toast";
import AutoComplete from "primevue/autocomplete";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

createApp(App)
    .use(store)
    .use(router)
    .use(PrimeVue)
    .use(ConfirmationService)
    .use(ToastService)
    .component("Toast", Toast)
    .component("AutoComplete", AutoComplete)
    .component('Column', Column)
    .component('DataTable', DataTable)
    .mount('#app')
