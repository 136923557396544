<template>
  <div>
    <top-header-bar/>
    <container class="py-10">
      <header class="py-4">
        <div class="flex items-center flex-wrap justify-between">
          <router-link to="/">
            <div id="logo" class="flex items-center">
              <img src="@/assets/images/logo--black.png"  class="mr-4" alt="">
            </div>
          </router-link>
          <div class="ml-auto flex items-center" v-if="isNavigationShown">
            <desktop-menu/>
          </div>
        </div>
      </header>
    </container>
  </div>
</template>

<script>
import DesktopMenu from "@/components/Menu/DesktopMenu";
import Container from "@/components/Container";
import TopHeaderBar from "@/components/TopHeaderBar";
import { useRouter } from 'vue-router';
import { computed } from 'vue';

export default {
  name: 'AppHeader',
  components: {TopHeaderBar, Container, DesktopMenu},
  setup() {
    const isNavigationShown = computed(() => {
      return !(useRouter().currentRoute.value.name === 'login');
    })
    return { isNavigationShown }
  }
}
</script>

<style scoped>

</style>
