import { UserService } from "@/services/apiService";
import {ACCESS_TOKEN_ID, destroyToken, getToken, saveToken} from '@/services/api/jwtService';

const state = {
    openModal: null,
    mobileMenu: null,
    authToken: null,
};

const mutations = {
    SET_OPEN_MODAL(state, modal) {
        state.openModal = modal
    },
    CLOSE_MODAL(state) {
        state.openModal = null
    },
    OPEN_MOBILE_MENU(state) {
        state.mobileMenu = true
    },
    CLOSE_MOBILE_MENU(state) {
        state.mobileMenu = false
    },
    SET_AUTH_TOKEN(state, authToken) {
        state.authToken = authToken;
    }
};

const actions = {
    openModal(context, modal) {
        context.commit('SET_OPEN_MODAL', modal)
    },
    closeModal(context) {
        context.commit('CLOSE_MODAL')
    },
    async login(context, loginInfo) {
        return UserService.login(loginInfo).then(payload => {
            if (!payload.status) return;
            const token = payload.data.accessToken;
            saveToken(ACCESS_TOKEN_ID, token);
            context.commit('SET_AUTH_TOKEN', token);
            return true;
        }).catch(e => {
            destroyToken();
            console.log('Is an issue!', e);
            return false;
        });
    },
    logout(context) {
        destroyToken();
        context.commit('SET_AUTH_TOKEN', null);
    },
    checkForLogin(context) {
        const token = getToken(ACCESS_TOKEN_ID)
        context.commit('SET_AUTH_TOKEN', token);
        return !!token;
    }

}

const getters = {
    openModal(state) {
        return state.openModal
    },
    mobileMenu(state) {
        return state.mobileMenu
    },
    authToken(state) {
        return state.authToken
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
