import jwt_decode from "jwt-decode";

export const ACCESS_TOKEN_ID = "ACCESS_TOKEN";

export const REFRESH_TOKEN_ID = "REFRESH_TOKEN";

export const getToken = (id = ACCESS_TOKEN_ID) => {
  return window.localStorage.getItem(id);
};

export const getUsername = () => {
  return window.localStorage.getItem('username');
};

export const saveToken = (id = ACCESS_TOKEN_ID, token) => {
  window.localStorage.setItem(id, token);
  let decodedToken = jwt_decode(token);
  if(decodedToken.username) {
    window.localStorage.setItem('username', decodedToken.username)
  }
};

export const destroyToken = (id = ACCESS_TOKEN_ID ) => {
  window.localStorage.removeItem(id);
};

export default { getToken, saveToken, destroyToken, REFRESH_TOKEN_ID, ACCESS_TOKEN_ID };
